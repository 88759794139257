// sort by name  a-z->
export function sortByName(arr) {
  arr.sort((a, b) => {
    const nameA = a.state.toUpperCase();
    const nameB = b.state.toUpperCase();
    if (nameA < nameB) {
      return -1;
    } else if (nameA > nameB) {
      return 1;
    } else {
      return 0;
    }
  });
  return arr;
}

//  sort array by name   z-a->
export function sortByName2(arr) {
  arr.sort((a, b) => {
    const nameA = a.state.toUpperCase();
    const nameB = b.state.toUpperCase();
    if (nameA < nameB) {
      return 1;
    }
    if (nameA > nameB) {
      return -1;
    }
    return 0;
  });
  return arr;
}

// sort array by date->

export function sortByDate(arr) {
  arr.sort(function (a, b) {
    const dateA = new Date(a.date);
    const dateB = new Date(b.date);
    return dateA - dateB;
  });
  return arr;
}

// sort by time->

export function sortByTime(arr) {
  arr.sort(function (a, b) {
    const timeA = new Date(`1970-01-01T${a}:00Z`);
    const timeB = new Date(`1970-01-01T${b}:00Z`);
    return timeB - timeA;
  });
  return arr;
}


export const formatNumberToIndian = (num) => {
  const strNum = num.toString();
  const afterFirstThree = strNum.slice(-3); // Last 3 digits
  const rest = strNum.slice(0, -3); // Remaining digits before the last 3

  if (rest) {
    return rest.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + "," + afterFirstThree;
  } else {
    return afterFirstThree; // If the number is less than 1000
  }
}