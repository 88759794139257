import { useState, useEffect } from "react";
import Navbar from "../Navbar/Navbar";
import Sidebar from "../Sidebar/Sidebar";
import useSWR from "swr";
import axios from "axios";
import moment from "moment";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { formatNumberToIndian, sortByDate, sortByTime } from "../helper";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRange } from "react-date-range";
import IPUrl from "../IP/url";
import { Box, Button, Dialog, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

const Orders = ({ searchQuery, onSearch }) => {
  const [showCalendar, setShowCalendar] = useState(false);
  const navigate = useNavigate();
  const [fromDate, setFromDate] = useState("2023-01-01")
  const [toDate, setToDate] = useState(moment().format("YYYY-MM-DD"))
  const [editableOder, setEditableOder] = useState({});
  const [showEditPopup, setShowEditPopup] = useState(false)


  function handleCalenderView() {
    setShowCalendar(true);
  }

  function hideCalendar() {
    setShowCalendar(false);
  }
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: null,
      key: "selection",
    },
  ]);


  // console.warn(state, "line 28");

  const getSelectedDate = state.map((i) => {
    const startDate = i.startDate.toISOString().split("T")[0];
    const endDate = i.endDate?.toISOString().split("T")[0];
    return {
      startDate,
      endDate,
    };
  });

  let dateArray = [];
  let currentDate = moment(getSelectedDate[0].startDate);
  let stopDate = moment(getSelectedDate[0].endDate);
  while (currentDate <= stopDate) {
    dateArray.push(moment(currentDate).format("YYYY-MM-DD"));
    currentDate = moment(currentDate).add(1, "days");
  }
  console.log(dateArray);

  const [adminId, setId] = useState("");

  useEffect(() => {
    const admin = JSON.parse(sessionStorage.getItem("admin"));
    if (admin) {
      setId(admin._id);
    }
  });

  const fetcher = (url) => axios.get(url).then((res) => res.data);
  const { data, error, loading } = useSWR(
    `${IPUrl}/admin/getAllOrders/${adminId}/?offset=${10}&fromDate=${fromDate}&toDate=${toDate}`,
    fetcher,
    {
      refreshInterval: 1000,
    }
  );
  const open2 = true;
  const [datas, setDatas] = useState([]);
  // orderQuantity
  const total = datas?.map(_i => _i.orderAmount).reduce((accumulator, currentValue) => accumulator + currentValue, 0);


  useEffect(() => {
    setDatas(data);
  }, [data]);

  const filtered = datas?.filter((order) => {
    return order?.number?.trim().toLowerCase().includes(searchQuery);
  });

  useEffect(() => {
    if (searchQuery?.length < 2 || searchQuery == "") {
      setDatas(data);
    } else {
      setDatas(filtered);
    }
  }, [searchQuery]);

  const [isOpen, setisOpen] = useState(false);
  const [accepted, setaccepted] = useState(false);
  const [rejected, setrejected] = useState(false);
  const [edit, setedit] = useState(false);
  const [index, setIndex] = useState(null);
  function handleMenuIconClick(id) {
    if (index == id) {
      setIndex(null);
    } else {
      setIndex(id);
    }
    // setIsOpen(!isOpen);
  }

  //  handle filter by dates->
  function filterBydates() {
    const newData = [...datas];
    const filtered = newData.filter((d) => {
      return dateArray.includes(d.date);
    });
    setDatas(filtered);
  }

  //  clear filter->

  function clearFilterDate() {
    setDatas(data);
  }

  const handleSort = () => {
    const sortedData = sortByDate([...datas]);

    setDatas(sortedData);
  };

  const handleSort2 = () => {
    const sortedData = sortByTime([...datas]);

    setDatas(sortedData);
  };

  // update order status  as aprooved ->
  const handleAccept = async (orderId) => {
    try {
      const { data } = axios.post(
        `${IPUrl}/admin/updateOrderStatus/${orderId}/${adminId}`,

        {
          orderStatus: "approved",
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      toast("Order Accepted");
      console.log(data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleReject = async (orderId) => {
    try {
      const { data } = axios.post(
        `${IPUrl}/admin/updateOrderStatus/${orderId}/${adminId}`,

        {
          orderStatus: "declined",
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      toast("Order Rejected");
      console.log(data);
    } catch (error) {
      console.log(error);
    }
  };

  const showPopup = (selectedItem) => { setEditableOder(selectedItem); setShowEditPopup(true) }

  const handleEditOrder = () => {
    console.log(editableOder.netPrice)
    var allPriceAdd = parseInt(editableOder.netPrice) +
      parseInt(editableOder["product"]["loading"]) +
      parseInt(editableOder["product"]["insurance"]) +
      parseInt(editableOder["product"]["tcs"]);

    const sizeAmount = editableOder["product"]["sizes"].filter(_itm => _itm.size == editableOder.productSize)[0]["amount"].replace("+", "");
    const gst = ((allPriceAdd + parseInt(sizeAmount)) *
      (editableOder["product"]["gst"] / 100))
    const orderAmountP = (parseInt(allPriceAdd) + parseInt(gst)) * parseFloat(editableOder.orderQuantity)
    setEditableOder({ ...editableOder, orderAmount: orderAmountP });

    const { data } = axios.put(
      `${IPUrl}/admin/updateOrder/${editableOder._id}`,

      {
        orderQuantity: parseInt(editableOder.orderQuantity), orderAmount: orderAmountP, netPrice: parseFloat(editableOder.netPrice)
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    setShowEditPopup(false);


  }
  return (
    <>
      <Dialog onClose={() => { setShowEditPopup(false) }} open={showEditPopup}>
        <Box sx={{ p: 2 }}>
          <Typography>{"Edit Order"}</Typography>
          <TextField value={editableOder?.orderQuantity} onChange={e => setEditableOder({ ...editableOder, orderQuantity: e.target.value })} />
        </Box>
        <Box sx={{ p: 2 }}>
          <Typography>{"Net Price"}</Typography>
          <Typography fontSize={10}>{"we will add gst, loading and insurance after this rate to calculate final order amount"}</Typography>


          <TextField value={editableOder?.netPrice} onChange={e => setEditableOder({ ...editableOder, netPrice: e.target.value })} />
        </Box>
        {/* <Box sx={{ p: 2 }}>
          <Typography>{"Total Order Amount"}</Typography>
          <TextField value={editableOder?.orderAmount} disabled />
        </Box> */}

        <Button sx={{ my: 1, mx: 2, background: "#7400B8" }} onClick={handleEditOrder} variant="contained">Submit</Button>

      </Dialog>
      <div className="base">
        <div>
          <Sidebar />
        </div>
        <div>
          <Navbar onSearch={onSearch} />
          {!data ? (
            <Backdrop
              open={open2}
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          ) : (
            <div
              style={{ height: "100vh", width: "80vw", background: "#EFEFEF", overflow: "hidden" }}
            >

              <div
                className="container"
                style={{
                  height: "87.5vh",
                  position: "relative",
                  width: "78vw",
                  marginLeft: "1vw",
                  // overflow: "auto",
                }}
              >
                {showCalendar && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      marginRight: "4vw",
                      marginTop: "4vh",
                    }}
                  >
                    <DateRange
                      editableDateInputs={true}
                      onChange={(item) => setState([item.selection])}
                      moveRangeOnFirstSelection={false}
                      ranges={state}
                    />
                  </div>
                )}
                {showCalendar && (
                  <>
                    <div style={{ display: "flex", gap: "20px" }}>
                      <button
                        onClick={filterBydates}
                        /*onClick={handleSubmit}*/ style={{
                          height: "5vh",
                          border: "0",
                          color: "#FFFFFF",
                          marginTop: "5vh",
                          marginLeft: "50vw",
                          width: "6vw",
                          background: "#0F9EE8",
                          borderRadius: "7px",
                          cursor: "pointer",
                        }}
                        type="button"
                      >
                        Apply
                      </button>
                      <button
                        onClick={clearFilterDate}
                        /*onClick={handleSubmit}*/ style={{
                          height: "5vh",
                          border: "0",
                          color: "#FFFFFF",
                          marginTop: "5vh",

                          width: "6vw",
                          background: "#0F9EE8",
                          borderRadius: "7px",
                          cursor: "pointer",
                        }}
                        type="button"
                      >
                        Clear
                      </button>
                      <button
                        onClick={hideCalendar}
                        /*onClick={handleSubmit}*/ style={{
                          height: "5vh",
                          border: "0",
                          color: "#FFFFFF",
                          marginTop: "5vh",

                          width: "6vw",
                          background: "#0F9EE8",
                          borderRadius: "7px",
                          cursor: "pointer",
                        }}
                        type="button"
                      >
                        Hide Calendar
                      </button>
                    </div>
                  </>
                )}

                {isOpen && (
                  <div
                    style={{
                      background: "#FFFFFF",
                      margin: "0vh 0vw 0vh 64vw",
                      position: "absolute",
                      width: "11vw",
                      borderRadius: "10px",
                      boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.15)",
                      zIndex: "1",
                      border: "none",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <h5
                        onClick={handleSort}
                        style={{
                          margin: " 1.5vh 1vw 1.5vh 1vw",
                          cursor: "pointer",
                        }}
                      >
                        Date
                      </h5>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        borderTop: "1px solid #777777",
                      }}
                    >
                      <h5
                        onClick={handleSort2}
                        style={{
                          margin: " 1.5vh 1vw 1.5vh 1vw",
                          cursor: "pointer",
                        }}
                      >
                        Time
                      </h5>
                    </div>
                    {/* <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderTop: "1px solid #777777",
                  }}
                >
                 
                </div> */}
                    {/* <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderTop: "1px solid #777777",
                  }}
                >
                  
                </div> */}
                  </div>
                )}
                <div style={{ display: "flex", justifyContent: "space-between", flexDirection: 'row', }}>
                  <div style={{ flex: 1, padding: 20 }}>
                    <h2>Orders</h2>
                    <h4>{"Total Order Value ₹" + formatNumberToIndian((total + "")?.split(".")[0])}</h4>
                  </div>
                  <div style={{ justifyContent: "flex-end", alignSelf: "center", }}>
                    <Button onClick={() => { navigate("/newOrder"); }} variant="contained" style={{ backgroundColor: "#7400B8" }} >New Order</Button>
                  </div>
                </div>
                <hr style={{ width: "77vw" }} />
                <Grid container xs={4} sx={{ my: 2 }}>
                  <Grid item xs={6}>
                    <Typography>{"From date"}</Typography>
                    <TextField type="date" value={fromDate} onChange={(e) => { setFromDate(e.target.value) }} />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography>{"To date"}</Typography>
                    <TextField type="date" value={toDate} onChange={(e) => { setToDate(e.target.value) }} />
                  </Grid>
                </Grid>

                <TableContainer component={Paper} style={{ minHeight: "70vh", }}>
                  <Table stickyHeader sx={{ minWidth: 650, tableLayout: "fixed" }} aria-label="simple table">
                    <TableHead >
                      <TableRow>
                        <TableCell style={{ color: "#7400B8", fontWeight: "bold" }}  >S.No{" "}</TableCell>
                        <TableCell style={{ color: "#7400B8", fontWeight: "bold" }} >Date{" "}</TableCell>
                        <TableCell style={{ color: "#7400B8", fontWeight: "bold" }}  >Full Name{" "}</TableCell>
                        <TableCell style={{ color: "#7400B8", fontWeight: "bold" }}  >Order Id{" "}</TableCell>
                        <TableCell style={{ color: "#7400B8", fontWeight: "bold" }}  >State{" "}</TableCell>
                        <TableCell style={{ color: "#7400B8", fontWeight: "bold" }}  >Product Name{" "}</TableCell>
                        <TableCell style={{ color: "#7400B8", fontWeight: "bold" }}  >Net Price{" "}</TableCell>
                        <TableCell style={{ color: "#7400B8", fontWeight: "bold" }}  >Quantity{" "}</TableCell>
                        <TableCell style={{ color: "#7400B8", fontWeight: "bold" }}  >Amount{" "}</TableCell>
                        <TableCell style={{ color: "#7400B8", fontWeight: "bold" }} textAlign="right" >  Action{" "}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {datas && datas?.map((item, i) =>

                        <TableRow>

                          <TableCell scope="row">
                            {i + 1}
                          </TableCell>
                          <TableCell scope="row">
                            {moment(item?.date).format("DD-MM-YYYY")}
                          </TableCell>
                          <TableCell scope="row">
                            {item.fullName}
                          </TableCell>
                          <TableCell scope="row">
                            {item.orderNumber}
                          </TableCell>
                          <TableCell scope="row">
                            {item.state}
                          </TableCell>
                          <TableCell scope="row">
                            {item.product?.stockName}
                          </TableCell>
                          <TableCell scope="row">
                            {"₹" + formatNumberToIndian(item.netPrice)}
                          </TableCell>
                          <TableCell scope="row">
                            {item.orderQuantity}
                          </TableCell>
                          <TableCell scope="row">
                            {"₹" + formatNumberToIndian(item.orderAmount)}
                          </TableCell>

                          <TableCell scope="row">
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-around",
                              }}
                            >
                              <>
                                <button

                                  onClick={() => {
                                    showPopup(item)
                                  }}
                                  style={{
                                    width: "5vw",
                                    height: "3vh",
                                    color: "#7400B8",
                                    background: "#FFFFFF",
                                    border: "1px solid #7400B8",
                                    borderRadius: "6px",
                                    cursor: "pointer",
                                  }}
                                >
                                  Edit
                                </button>


                                {item.status === "pending" ? (
                                  <>
                                    <button
                                      onClick={() => handleAccept(item._id)}
                                      style={{
                                        width: "5vw",
                                        height: "3vh",
                                        color: "#7400B8",
                                        background: "#FFFFFF",
                                        border: "1px solid #7400B8",
                                        borderRadius: "6px",
                                        cursor: "pointer",
                                      }}
                                    >
                                      Accept
                                    </button>
                                    <ToastContainer
                                      position="top-center"
                                      autoClose={5000}
                                      hideProgressBar={false}
                                      newestOnTop={false}
                                      closeOnClick
                                      rtl={false}
                                      pauseOnFocusLoss
                                      draggable
                                      pauseOnHover
                                      success
                                      theme="dark"
                                    />
                                    <button
                                      onClick={() => handleReject(item._id)}
                                      style={{
                                        width: "5vw",
                                        height: "3vh",
                                        color: "#7400B8",
                                        background: "#FFFFFF",
                                        border: "1px solid #7400B8",
                                        borderRadius: "6px",
                                        cursor: "pointer",
                                      }}
                                    >
                                      Reject
                                    </button>
                                    <ToastContainer
                                      position="top-center"
                                      autoClose={5000}
                                      hideProgressBar={false}
                                      newestOnTop={false}
                                      closeOnClick
                                      rtl={false}
                                      pauseOnFocusLoss
                                      draggable
                                      pauseOnHover
                                      success
                                      theme="dark"
                                    />
                                  </>
                                ) : item.status === "approved" ? (
                                  <button
                                    style={{
                                      width: "5vw",
                                      height: "3vh",
                                      color: "#7400B8",
                                      background: "#FFFFFF",
                                      border: "1px solid #7400B8",
                                      borderRadius: "6px",
                                      cursor: "pointer",
                                    }}
                                  >
                                    Accepted
                                  </button>
                                ) : item.status === "declined" ? (
                                  <button
                                    style={{
                                      width: "5vw",
                                      height: "3vh",
                                      color: "#7400B8",
                                      background: "#FFFFFF",
                                      border: "1px solid #7400B8",
                                      borderRadius: "6px",
                                      cursor: "pointer",
                                    }}
                                  >
                                    Rejected
                                  </button>
                                ) : null}
                              </>
                            </div>
                          </TableCell>

                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>

              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default Orders;


