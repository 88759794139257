import React, { useState, useRef, useEffect } from "react";
import Navbar from "../Navbar/Navbar";
import Sidebar from "../Sidebar/Sidebar";
import upload from "./large.png";
import axios from "axios";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import { admin, baseUrl, states } from "../localdata";
import IPUrl from "../IP/url";
import IPUrl7000 from "../IP/url2";
import useSWR from "swr";
import moment from "moment";
import {
  BiDotsVerticalRounded,
  BiLeftArrowAlt,
  BiRightArrowAlt,
} from "react-icons/bi";
import { MdModeEditOutline } from "react-icons/md";
import { Link } from "react-router-dom";
import { Box, Grid, Modal, TextField, Typography } from "@mui/material";
import { Button } from "react-scroll";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  background: "#fff",
};

const UploadBill = () => {
  const [adminId, setId] = useState("");
  const { handleSubmit, formState } = useForm();
  const { isSubmitting } = formState;
  useEffect(() => {
    const admin = JSON.parse(sessionStorage.getItem("admin"));
    if (admin) {
      setId(admin._id);
    }
  });
  const [userName, setUserName] = useState("");
  const [userNumber, setUserNumber] = useState("");
  const [pdf, setPdf] = useState("");
  const [userdata, setUserdata] = useState([]);
  const [userIndex, setUserIndex] = useState();
  const [orderIndex, setOrderIndex] = useState();
  const [fileUrls, setFileUrls] = useState([]);
  const [billAmount, setBillAmount] = useState(0)
  const [billQty, setBillQty] = useState(0)

  const [showModal, setShowModal] = useState(false);

  // const imageRef = useRef();
  // const spanref = useRef();

  const fetcher = (url) => axios.get(url).then((res) => res.data);
  const { data, error, loading } = useSWR(
    ` ${IPUrl7000}/order-bill/getAllOrderBills`,
    fetcher,
    {
      refreshInterval: 1000,
    }
  );

  // useEffect(() => {
  //   const element = imageRef.current;

  //   element.addEventListener("change", () => {
  //     const inputImage = element.files[0];
  //     spanref.current.innerHTML = inputImage.name;
  //   });
  // });

  const toggleModal = () => setShowModal(!showModal);

  const submitForm = async (e) => {
    let myForm = {
      name: userName,
      mobile: userNumber,
    };

    try {
      const { data } = await axios.get(
        `${IPUrl7000}/order-bill/findUserAndTheirOrder?name=${userName}&mobile=${userNumber}`,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      toast("Order data fetched successfully!");
      console.log(data);
      setUserdata(data.user);
    } catch (error) {
      toast.error("Something went wrong");
      console.log(error);
    }
  };

  // handle file change
  const handleFileChange = async (event) => {
    const selectedFile = event.target.files;
    console.log(event.target.files, "sleected file array");
    // // setFile(selectedFile);

    if (selectedFile) {
      Array.from(selectedFile)?.map((data, index) => {
        // console.log(selectedFile.length - 1, index,data);
        UploadFile(data, index, selectedFile.length - 1);
      });
    }
  };

  const UploadFile = async (selectedFile, index, length) => {
    const formData = new FormData();
    let file = [];

    formData.append("image", selectedFile);
    try {
      const response = await axios.post(`${IPUrl7000}/api/upload`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (response.status === 200) {
        let payload = {
          fileOriginalName: selectedFile.name,
          fileType: selectedFile.type,
          fileLink: response.data.url,
        };
        console.log(response);
        // file.push(response.data.url)
        setFileUrls((prev) => [...prev, response.data.url]);
        // createCompanyStock(payload, index, length);
      }
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  const createBill = async () => {
    if (billAmount == 0) {
      toast.error("Plese enter the bill amount");
      return false
    }
    if (billQty === 0) {
      toast.error("Plese enter the bill qty.");
      return false
    }
    if (fileUrls.length > 0) {
      // console.log(fileUrls);
      let data = {
        userId: userIndex,
        orderId: orderIndex,
        bills: fileUrls,
        amount: billAmount,
        quantity: billQty

      };
      console.log(data)
      await axios
        .post(`${IPUrl7000}/order-bill/createOrderBill`, data)
        .then((res2) => {
          if (res2.status === 200) {
            console.log(res2);
            // GetDetailsofStockById(data.companyId);
            toggleModal();

            toast(res2.data.message);
          }
        })
        .catch((err2) => {
          console.log(err2, "Error in uplaod to company stock");
        });
    } else {
      toast.error("Select atleast one file to create bill!");
    }
  };

  function handleMenuIconClick(id) {
    console.log(id, userdata, "get id on click");
    setUserIndex(userdata.id);
    setOrderIndex(id);
    toggleModal();

    // setIsOpen(!isOpen);
  }
  return (
    <>
      <div className="base">
        <div>
          <Sidebar />
        </div>
        <div>
          <Navbar />
          <form onSubmit={handleSubmit(submitForm)}>
            <div
              style={{
                height: "40vh",
                overflow: "scroll",
                width: "74vw",
                paddingLeft: "6vw",
                background: "#EFEFEF",
              }}
            >
              <div style={{ display: "flex", paddingTop: "6vh" }}>
                <div>
                  <h3 style={{ marginLeft: "2vw" }}>User Name</h3>
                  <input
                    type="text"
                    // required
                    value={userName}
                    onChange={(e) => setUserName(e.target.value)}
                    placeholder="    User Name"
                    style={{ height: "4vh", width: "30vw", marginLeft: "2vw" }}
                  />
                </div>
                <div>
                  <h3 style={{ marginLeft: "2vw" }}>User Number</h3>

                  <input
                    type="text"
                    // required
                    value={userNumber}
                    onChange={(e) => setUserNumber(e.target.value)}
                    placeholder="   User Number"
                    style={{ height: "4vh", width: "30vw", marginLeft: "2vw" }}
                  />
                </div>
              </div>

              <div style={{ display: "flex" }}></div>

              {/* <div
                style={{ display: "flex", flexDirection: "column", gap: "1vw" }}
              >
                <label
                  htmlFor=""
                  style={{ fontWeight: "bold", marginTop: "1vh" }}
                >
                  Choose a file
                </label>
                <label htmlFor="excel">
                  <img
                    src={upload}
                    alt="upload"
                    style={{
                      width: "6vw",
                      cursor: "pointer",
                      marginTop: "2vh",
                    }}
                  />
                </label>
              </div> */}
              {/* 
              <input
                ref={imageRef}
                style={{ display: "none" }}
                id="excel"
                type="file"
                onChange={handleFileChange}
              />
              <span
                style={{ fontSize: "12px", marginTop: "5vh" }}
                ref={spanref}
              ></span> */}
              {/* <div style={{ display: "grid" }}>
                {formData.map((data, index) =>
                  generateInputFields(data, index)
                )}
              </div> */}
              <button
                disabled={isSubmitting}
                style={{
                  color: "#FFFFFF",
                  background: "#7400B8",
                  height: "4vh",
                  width: "10vw",
                  marginTop: "4vh",
                  marginLeft: "53vw",
                  border: "none",
                  borderRadius: "9px",
                }}
                type="submit"
              >
                Upload
              </button>

              {isSubmitting && (
                <span style={{ fontWeight: "bold", marginLeft: "1vmax" }}>
                  Loading...
                </span>
              )}

              <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                success
                theme="dark"
              />
            </div>
          </form>
          {userdata?.orders?.length > 0 && (
            <div
              className="container1"
              style={{
                width: "80vw",
                height: "60vh",
                overflow: "auto",
                background: "#EFEFEF",
              }}
            >
              <table className="tab" style={{ width: "100%", height: "100%" }}>
                <tr className="tabtr">
                  <th style={{ textAlign: "start", color: "#7400B8" }}>
                    S. No.
                  </th>
                  <th style={{ textAlign: "start", color: "#7400B8" }}>
                    Full Name
                  </th>
                  <th style={{ textAlign: "start", color: "#7400B8" }}>
                    Mobile Number
                  </th>
                  <th style={{ textAlign: "start", color: "#7400B8" }}>
                    Product
                  </th>
                  <th style={{ textAlign: "center", color: "#7400B8" }}>
                    Product Size
                  </th>
                  <th style={{ textAlign: "start", color: "#7400B8" }}>
                    Net Price
                  </th>
                  <th style={{ textAlign: "start", color: "#7400B8" }}>
                    Order Quantity
                  </th>
                  <th style={{ textAlign: "start", color: "#7400B8" }}>
                    Order Amount
                  </th>
                  <th style={{ textAlign: "start", color: "#7400B8" }}>
                    Order Number
                  </th>
                  <th style={{ textAlign: "start", color: "#7400B8" }}>
                    Action
                  </th>
                </tr>

                {userdata?.orders?.map((item, i) => (
                  <tr
                    key={i}
                    style={{ paddingTop: "2vh", position: "relative" }}
                  >
                    <td
                      style={{
                        textAlign: "start",
                      }}
                    >
                      {i + 1}
                    </td>
                    <td
                      style={{
                        textAlign: "start",
                      }}
                    >
                      {item.fullName}
                    </td>
                    <td
                      style={{
                        textAlign: "start",
                      }}
                    >
                      {item.number}
                    </td>
                    <td
                      style={{
                        textAlign: "start",
                      }}
                    >
                      {item.product?.stockName ?? ""}
                    </td>
                    <td
                      style={{
                        textAlign: "start",
                      }}
                    >
                      {item.productSize}
                    </td>
                    <td
                      style={{
                        textAlign: "center",
                      }}
                    >
                      {item.netPrice}
                    </td>
                    <td
                      style={{
                        textAlign: "start",
                      }}
                    >
                      {item.orderQuantity}
                    </td>
                    <td
                      style={{
                        textAlign: "start",
                      }}
                    >
                      {item.orderAmount}
                    </td>
                    <td
                      style={{
                        textAlign: "start",
                      }}
                    >
                      {item.orderNumber}
                    </td>
                    <td
                      style={{
                        "font-size": "15px",
                        textAlign: "center",
                      }}
                    >
                      <a
                        onClick={() => handleMenuIconClick(item._id)}
                        style={{ cursor: "pointer" }}
                      >
                        <MdModeEditOutline style={{ paddingRight: "2vw" }} />
                      </a>
                    </td>
                  </tr>
                ))}
              </table>
            </div>
          )}
        </div>
      </div>

      <Modal
        open={showModal}
        onClose={toggleModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Add Bill
          </Typography>

          <Grid container xs={12} mt={2}>
            <Grid item xs={5} sx={{ mx: 1 }}>
              <Typography color={"gray"}>{"Bill Amount"}</Typography>
              <TextField sx={{ my: 2 }} value={billAmount} placeholder="Bill Amount" onChange={(e) => { setBillAmount(e.target.value) }} />
            </Grid>
            <Grid item xs={5} sx={{ mx: 1 }}>
              <Typography color={"gray"}>{"Bill Qty."}</Typography>

              <TextField sx={{ my: 2 }} value={billQty} placeholder="Qty" onChange={(e) => { setBillQty(e.target.value) }} />
            </Grid>
          </Grid>
          <div>
            <input type="file" multiple onChange={handleFileChange} />
          </div>
          <Button style={{
            marginTop: "20px",
            background: "#7400B8",
            color: "white",
            padding: "6px",
            borderColor: "#7400B8",
            flex: 1,
            borderRadius: 10,
            width: "100%"
          }} variant="contained" onClick={() => createBill()}>{"Add Bill"}</Button>

        </Box>
      </Modal>
    </>
  );
};

export default UploadBill;
