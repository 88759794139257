import { useEffect, useState } from "react";
import { Autocomplete, Button, Grid, Snackbar, TextField, Typography } from "@mui/material"
import Navbar from "../Navbar/Navbar"
import Sidebar from "../Sidebar/Sidebar"
import axios from "axios";
import useSWR from "swr";
import IPUrl from "../IP/url";
import moment from "moment/moment";
import { ToastContainer, toast } from "react-toastify";


export const NewOrder = ({ searchQuery, onSearch }) => {

    const [adminId, setId] = useState("");
    const [currentId, setcurrentId] = useState("")
    const [AllStockDetail, setAllStockDetail] = useState([]);
    const [stateData, setStateData] = useState([]);
    const [selectedStockType, setSelectedStockType] = useState("B2B")
    const [stockName, setStockName] = useState([])
    const [size, setSize] = useState([])
    const [selectedStock, setSelectedStock] = useState(null)
    const [selectedSize, setSelectedSize] = useState(null)
    const [orderAmount, setOrderAmount] = useState("")
    const [netAmount, setNetAmount] = useState("")
    const [price, setPrice] = useState("")


    const [orderDetails, setOrderDetails] = useState({
        date: "",
        time: "",
        fullName: "",
        number: "",
        state: "",
        netPrice: "",
        orderQuantity: "",
        orderAmount: "",
        stockId: "",
        productSize: "",
        price: ""
    })

    useEffect(() => {
        if (selectedSize != null) {
            setPrice(selectedSize["amount"].replace("+", "").replace("-", ""))
        }
    }, [selectedSize])

    useEffect(() => {
        const admin = JSON.parse(sessionStorage.getItem("admin"));
        if (admin) {
            setId(admin._id);
        }
    });


    useEffect(() => {
        const fetchStateData = async () => {
            try {
                const response = await axios.get(
                    `${IPUrl}/admin/getB2BStock/${adminId}`
                );
                const response2 = await axios.get(
                    `${IPUrl}/admin/getB2CStock/${adminId}`
                );
                const stockListData = response.data.concat(response2.data);
                setAllStockDetail(stockListData);
                // console.log(stockListData);

                const uniqueStockData = response.data.filter(
                    (item, index, self) =>
                        index ===
                        self.findIndex((stock) => stock.stateName === item.stateName)
                );

                setStateData(uniqueStockData);
            } catch (error) {
                console.error("Error fetching stock data:", error);
            }
        };

        // Set up the interval
        // const intervalId = setInterval(() => {
        //   fetchStateData();
        // }, 1000);
        fetchStateData();

        // return () => clearInterval(intervalId);
    }, [IPUrl, adminId]);


    const sendOrder = async () => {
        if (orderAmount.date == "") {
            updateOrderDetails("date", moment().format("l"))
        }
        if (orderAmount.time == "") {
            updateOrderDetails("time", moment().format("LT"))
        }
        const data = await axios.post(`${IPUrl}/user/sendOrderAdmin`, orderDetails);
        console.log(data);
        if (data) {
            toast.success("Order Placed");
        }
    }


    // call all the user data 
    const fetcher = (url) => axios.get(url).then((res) => res.data);
    const { data, error, loading } = useSWR(
        ` ${IPUrl}/admin/userManagement/${adminId}`,
        fetcher,
        {
            refreshInterval: 1000,
        }
    );


    const showingData = data?.map(_i => ({ id: _i._id, fullName: _i.fullName, number: _i.number }))




    async function handleNumberChange(e) {
        const namedata = showingData?.filter((user) => {
            return user?.number?.trim().includes(e);
        }) ?? "";
        setcurrentId(namedata[0].id)
        let tmp = orderDetails;
        tmp["fullName"] = namedata[0].fullName;
        setOrderDetails(tmp)
    }

    async function handleNameChange(e) {
        const numberdata = showingData?.filter((user) => {
            return user?.fullName?.trim().includes(e);
        }) ?? "";
        setcurrentId(numberdata[0].id)
        let tmp = orderDetails;
        tmp["number"] = numberdata[0].number;
        setOrderDetails(tmp)
    }

    const updateOrderDetails = (key, value) => {
        let tempOrder = orderDetails;
        tempOrder[key] = value;
        console.log(tempOrder)
        setOrderDetails(tempOrder);
        if (key == "fullName" && value != "") {
            handleNameChange(value != null ? value : "")
        }
        if (key == "number" && value != "") {
            handleNumberChange(value != null ? value : "")
        }
        if (key == "state" && value != "" && value != null) {
            updateStockData(value)
        }
        if (key == "stockId") {
            updateSizeValue(value)
        }
        if (key == "price") {
            setPrice(value);
        }
    }

    const calcualteValues = (qty) => {


        if (qty != "" && selectedStock != null && selectedSize != null) {
            let base = parseInt(selectedStock["basic"])
            let loading = parseInt(selectedStock["loading"])
            let ins = parseInt(selectedStock["insurance"]);
            let amount = parseInt(price)
            let ggstt = parseInt(selectedStock["gst"])
            let tsc = parseInt(selectedStock["tcs"])

            const totalAmount = base +
                loading + ins;
            const netprice = (parseInt(base) + parseInt(price)) * qty

            var loadingPrice = loading * (qty ?? 1);
            var insurance = ins * (qty ?? 1);
            var netPrice =
                (base + amount) *
                (qty ?? 1);
            var gstPrice = ((netPrice + loadingPrice + insurance) *
                (ggstt / 100));

            const totalNetAmount = tsc + totalAmount + ((totalAmount + amount) * ggstt / 100)
            const orderAmount = netprice + gstPrice + loadingPrice + insurance;
            updateOrderDetails("orderAmount", orderAmount);
            updateOrderDetails("netPrice", totalNetAmount);
            setOrderAmount(orderAmount)
            setNetAmount(totalNetAmount);


        }

    }


    const updateSizeValue = (selectedStockId) => {
        let temp = AllStockDetail;
        let setData = temp.filter(_i => _i._id == selectedStockId)[0]?.sizes.map(_j => ({ ..._j, label: _j.size }));
        console.log({ setData })
        setSize(setData);
    }
    const updateStockData = (selectedStateName) => {
        let temp = AllStockDetail;
        let setData = temp.filter(_i => _i.stateName.toLowerCase() === selectedStateName.toLowerCase()).map(_j => ({ ..._j, label: _j.stockName }))
        setStockName(setData);

    }



    const nameArr = showingData?.map(_i => _i.fullName);
    const numArr = showingData?.map(_i => _i.number);
    return <div className="base">


        <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            success
            theme="dark"
        />



        <div>
            <Sidebar />
        </div>

        <div>
            <Navbar onSearch={onSearch} />
            <div
                className="container"
                style={{
                    height: "87.5vh",
                    position: "relative",
                    width: "78vw",
                    marginLeft: "1vw",
                    overflow: "auto",
                }}
            >

                <div style={{ display: "flex", justifyContent: "space-between", flexDirection: 'row', }}>

                    <Grid container>

                        <Grid item xs={6} sx={{ my: 2 }}>
                            {/* date and time  */}

                            <TextField onChange={(e) => {
                                updateOrderDetails("date", moment(e.target.value).format('l'))
                                updateOrderDetails("time", moment(e.target.value).format('LT'))
                            }} sx={{ flex: 1, mx: 5 }} placeholder="Date / Time" type="datetime-local"
                                inputProps={{
                                    max: moment().format("YYYY-MM-DDTHH:mm"), // Restrict future dates/times
                                }}
                            />
                            <Typography sx={{ mx: 5, fontSize: 12, color: "gray" }}>{"You can only select past dates"}</Typography>
                        </Grid>
                        <Grid item xs={6} sx={{ my: 2 }}>
                            {/* Name */}
                            <Autocomplete value={orderDetails.fullName} onChange={(event, newValue) => {
                                updateOrderDetails("fullName", newValue)
                            }}
                                sx={{ flex: 1, mx: 5 }} placeholder="Name" options={nameArr ?? []} renderInput={(params) => <TextField {...params} label="Name" />} />

                        </Grid>


                        <Grid item xs={6} sx={{ my: 2 }}>
                            {/* Number */}
                            <Autocomplete sx={{ flex: 1, mx: 5 }} onChange={(event, newValue) => {
                                updateOrderDetails("number", newValue)
                            }} value={orderDetails.number} placeholder="Phone" options={numArr ?? []} renderInput={(params) => <TextField {...params} label="Number" />} />


                        </Grid>
                        <Grid item xs={6} sx={{ my: 2 }}>

                            {/* State */}
                            <Autocomplete sx={{ flex: 1, mx: 5 }} onChange={(event, newValue) => {
                                updateOrderDetails("state", newValue)
                            }} value={orderDetails.state} placeholder="State" options={stateData.map(_i => _i.stateName) ?? []} renderInput={(params) => <TextField {...params} label="State" />} />

                        </Grid>
                        <Grid item xs={6} sx={{ my: 2 }}>
                            <Autocomplete
                                getOptionLabel={(option) => option.label} // Display the label in the dropdown
                                onChange={(event, newValue) => {
                                    console.log(newValue)
                                    if (newValue) {
                                        updateOrderDetails("stockId", newValue._id)
                                        setSelectedStock(newValue); // Log the ID of the selected option
                                    } else {
                                        console.log("No option selected");
                                    }
                                }}
                                value={selectedStock} // Initial value (no default selection)
                                sx={{ flex: 1, mx: 5 }}
                                options={stockName} // Provide the JSON array as options
                                renderInput={(params) => (
                                    <TextField
                                        {...params} // Spread the params for proper rendering
                                        label="Stock" // Add a label for the input
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={6} sx={{ my: 2 }}>


                            {/* size */}


                            <Autocomplete
                                getOptionLabel={(option) => option.label} // Display the label in the dropdown
                                onChange={(event, newValue) => {
                                    if (newValue) {
                                        updateOrderDetails("productSize", newValue.size)
                                        setSelectedSize(newValue)
                                    } else {
                                        console.log("No option selected");
                                    }
                                }}
                                value={selectedSize} // Initial value (no default selection)
                                sx={{ flex: 1, mx: 5 }}
                                options={size} // Provide the JSON array as options
                                renderInput={(params) => (
                                    <TextField
                                        {...params} // Spread the params for proper rendering
                                        label="Size" // Add a label for the input
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sx={{ my: 2, mx: 4 }}>

                            <TextField sx={{ mx: 2 }} onChange={(e) => { updateOrderDetails("orderQuantity", e.target.value) }} placeholder="Order Quantity" />
                            <TextField sx={{ mx: 2 }} onChange={(e) => { updateOrderDetails("price", e.target.value) }} value={price} placeholder="price" />
                            <TextField sx={{ mx: 2 }} placeholder="net price" value={netAmount} disabled />
                            <TextField sx={{ mx: 2 }} placeholder="orderAmount" value={orderAmount} disabled />


                        </Grid>



                        {/* prefill */}

                        <div style={{ display: "flex", alignSelf: "center", justifyContent: "center", flex: 1 }}>
                            <Button variant="contained" sx={{ mx: 2 }} onClick={() => { calcualteValues(orderDetails.orderQuantity) }}>Calculate</Button>
                            <Button variant="contained" sx={{ mx: 2 }} onClick={() => { sendOrder() }}>Create order</Button>
                        </div>

                    </Grid>




                </div>
            </div>
        </div>


    </div>

}